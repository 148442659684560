import React from "react"

const Loading = () => {
  return (
    <svg
      width="170"
      height="45"
      viewBox="0 0 170 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Loading...">
        <path
          id="L"
          d="M2.00361 29C1.61961 29 1.28361 28.868 0.99561 28.604C0.731609 28.34 0.599609 28.004 0.599609 27.596V2.288C0.599609 1.904 0.731609 1.58 0.99561 1.316C1.28361 1.028 1.61961 0.884003 2.00361 0.884003C2.38761 0.884003 2.71161 1.028 2.97561 1.316C3.26361 1.58 3.40761 1.904 3.40761 2.288V26.336H17.4836C17.8676 26.336 18.1916 26.468 18.4556 26.732C18.7436 26.972 18.8876 27.284 18.8876 27.668C18.8876 28.052 18.7436 28.376 18.4556 28.64C18.1916 28.88 17.8676 29 17.4836 29H2.00361Z"
          fill="black"
        />
        <path
          id="O"
          d="M31.4449 29.18C29.5249 29.18 27.8089 28.748 26.2969 27.884C24.7849 27.02 23.5969 25.832 22.7329 24.32C21.8689 22.808 21.4369 21.092 21.4369 19.172C21.4369 17.228 21.8689 15.5 22.7329 13.988C23.5969 12.476 24.7849 11.288 26.2969 10.424C27.8089 9.56 29.5249 9.128 31.4449 9.128C33.3649 9.128 35.0689 9.56 36.5569 10.424C38.0689 11.288 39.2569 12.476 40.1209 13.988C40.9849 15.5 41.4289 17.228 41.4529 19.172C41.4529 21.092 41.0089 22.808 40.1209 24.32C39.2569 25.832 38.0689 27.02 36.5569 27.884C35.0689 28.748 33.3649 29.18 31.4449 29.18ZM31.4449 26.66C32.8369 26.66 34.0849 26.336 35.1889 25.688C36.2929 25.04 37.1569 24.152 37.7809 23.024C38.4049 21.896 38.7169 20.612 38.7169 19.172C38.7169 17.732 38.4049 16.448 37.7809 15.32C37.1569 14.168 36.2929 13.268 35.1889 12.62C34.0849 11.972 32.8369 11.648 31.4449 11.648C30.0529 11.648 28.8049 11.972 27.7009 12.62C26.5969 13.268 25.7209 14.168 25.0729 15.32C24.4489 16.448 24.1369 17.732 24.1369 19.172C24.1369 20.612 24.4489 21.896 25.0729 23.024C25.7209 24.152 26.5969 25.04 27.7009 25.688C28.8049 26.336 30.0529 26.66 31.4449 26.66Z"
          fill="black"
        />
        <path
          id="A2"
          d="M54.9801 29.18C53.1321 29.18 51.4761 28.748 50.0121 27.884C48.5481 26.996 47.3841 25.796 46.5201 24.284C45.6801 22.772 45.2601 21.068 45.2601 19.172C45.2601 17.252 45.6921 15.536 46.5561 14.024C47.4441 12.512 48.6441 11.324 50.1561 10.46C51.6681 9.572 53.3721 9.128 55.2681 9.128C57.1641 9.128 58.8561 9.572 60.3441 10.46C61.8561 11.324 63.0441 12.512 63.9081 14.024C64.7961 15.536 65.2521 17.252 65.2761 19.172V27.632C65.2761 28.04 65.1441 28.376 64.8801 28.64C64.6161 28.88 64.2801 29 63.8721 29C63.4641 29 63.1281 28.88 62.8641 28.64C62.6001 28.376 62.4681 28.04 62.4681 27.632V25.436C61.6521 26.588 60.5961 27.5 59.3001 28.172C58.0041 28.844 56.5641 29.18 54.9801 29.18ZM55.2681 26.66C56.6601 26.66 57.8961 26.336 58.9761 25.688C60.0801 25.04 60.9441 24.152 61.5681 23.024C62.2161 21.872 62.5401 20.588 62.5401 19.172C62.5401 17.732 62.2161 16.448 61.5681 15.32C60.9441 14.192 60.0801 13.304 58.9761 12.656C57.8961 11.984 56.6601 11.648 55.2681 11.648C53.9001 11.648 52.6641 11.984 51.5601 12.656C50.4561 13.304 49.5801 14.192 48.9321 15.32C48.2841 16.448 47.9601 17.732 47.9601 19.172C47.9601 20.588 48.2841 21.872 48.9321 23.024C49.5801 24.152 50.4561 25.04 51.5601 25.688C52.6641 26.336 53.9001 26.66 55.2681 26.66Z"
          fill="black"
        />
        <path
          id="D"
          d="M79.0914 29.18C77.1954 29.18 75.4914 28.748 73.9794 27.884C72.4674 26.996 71.2674 25.796 70.3794 24.284C69.5154 22.772 69.0834 21.056 69.0834 19.136C69.0834 17.24 69.5034 15.536 70.3434 14.024C71.2074 12.512 72.3714 11.324 73.8354 10.46C75.2994 9.572 76.9554 9.128 78.8034 9.128C80.4114 9.128 81.8634 9.476 83.1594 10.172C84.4554 10.844 85.4994 11.744 86.2914 12.872V2.288C86.2914 1.856 86.4234 1.52 86.6874 1.28C86.9514 1.016 87.2874 0.884003 87.6954 0.884003C88.1034 0.884003 88.4394 1.016 88.7034 1.28C88.9674 1.52 89.0994 1.856 89.0994 2.288V19.28C89.0514 21.152 88.5834 22.844 87.6954 24.356C86.8314 25.844 85.6434 27.02 84.1314 27.884C82.6434 28.748 80.9634 29.18 79.0914 29.18ZM79.0914 26.66C80.4834 26.66 81.7194 26.336 82.7994 25.688C83.9034 25.016 84.7674 24.116 85.3914 22.988C86.0394 21.86 86.3634 20.576 86.3634 19.136C86.3634 17.72 86.0394 16.448 85.3914 15.32C84.7674 14.168 83.9034 13.268 82.7994 12.62C81.7194 11.972 80.4834 11.648 79.0914 11.648C77.7234 11.648 76.4874 11.972 75.3834 12.62C74.2794 13.268 73.4034 14.168 72.7554 15.32C72.1074 16.448 71.7834 17.72 71.7834 19.136C71.7834 20.576 72.1074 21.86 72.7554 22.988C73.4034 24.116 74.2794 25.016 75.3834 25.688C76.4874 26.336 77.7234 26.66 79.0914 26.66Z"
          fill="black"
        />
        <path
          id="I2"
          d="M97.0205 29C96.5885 29 96.2405 28.88 95.9765 28.64C95.7365 28.376 95.6165 28.028 95.6165 27.596V10.712C95.6165 10.28 95.7365 9.944 95.9765 9.704C96.2405 9.44 96.5885 9.308 97.0205 9.308C97.4285 9.308 97.7525 9.44 97.9925 9.704C98.2565 9.944 98.3885 10.28 98.3885 10.712V27.596C98.3885 28.028 98.2565 28.376 97.9925 28.64C97.7525 28.88 97.4285 29 97.0205 29ZM96.9845 5.564C96.4565 5.564 96.0005 5.372 95.6165 4.988C95.2325 4.604 95.0405 4.136 95.0405 3.584C95.0405 2.984 95.2325 2.516 95.6165 2.18C96.0245 1.82 96.4925 1.64 97.0205 1.64C97.5245 1.64 97.9685 1.82 98.3525 2.18C98.7605 2.516 98.9645 2.984 98.9645 3.584C98.9645 4.136 98.7725 4.604 98.3885 4.988C98.0045 5.372 97.5365 5.564 96.9845 5.564Z"
          fill="black"
        />
        <path
          id="N2"
          d="M106.311 29C105.879 29 105.531 28.88 105.267 28.64C105.027 28.376 104.907 28.04 104.907 27.632V10.712C104.907 10.28 105.027 9.944 105.267 9.704C105.531 9.44 105.879 9.308 106.311 9.308C106.719 9.308 107.043 9.44 107.283 9.704C107.547 9.944 107.679 10.28 107.679 10.712V11.936C108.447 11.048 109.395 10.352 110.523 9.848C111.675 9.344 112.911 9.092 114.231 9.092C115.935 9.092 117.459 9.452 118.803 10.172C120.147 10.868 121.203 11.9 121.971 13.268C122.763 14.636 123.159 16.304 123.159 18.272V27.632C123.159 28.016 123.027 28.34 122.763 28.604C122.499 28.868 122.175 29 121.791 29C121.383 29 121.047 28.868 120.783 28.604C120.519 28.34 120.387 28.016 120.387 27.632V18.272C120.387 16.04 119.763 14.396 118.515 13.34C117.291 12.26 115.767 11.72 113.943 11.72C112.767 11.72 111.699 11.948 110.739 12.404C109.803 12.86 109.059 13.484 108.507 14.276C107.955 15.068 107.679 15.968 107.679 16.976V27.632C107.679 28.04 107.547 28.376 107.283 28.64C107.043 28.88 106.719 29 106.311 29Z"
          fill="black"
        />
        <path
          id="G2"
          d="M137.406 29.18C135.51 29.18 133.83 28.76 132.366 27.92C130.902 27.056 129.75 25.868 128.91 24.356C128.094 22.844 127.686 21.116 127.686 19.172C127.686 17.204 128.118 15.464 128.982 13.952C129.846 12.44 131.034 11.264 132.546 10.424C134.058 9.56 135.774 9.128 137.694 9.128C139.638 9.128 141.354 9.56 142.842 10.424C144.354 11.264 145.53 12.44 146.37 13.952C147.234 15.464 147.678 17.204 147.702 19.172V26.516C147.702 28.46 147.27 30.2 146.406 31.736C145.542 33.296 144.366 34.52 142.878 35.408C141.39 36.32 139.686 36.776 137.766 36.776C135.87 36.776 134.202 36.416 132.762 35.696C131.322 35 130.134 34.04 129.198 32.816C128.934 32.528 128.814 32.216 128.838 31.88C128.886 31.544 129.066 31.268 129.378 31.052C129.69 30.836 130.038 30.764 130.422 30.836C130.806 30.908 131.106 31.088 131.322 31.376C132.018 32.24 132.906 32.936 133.986 33.464C135.09 33.992 136.362 34.256 137.802 34.256C139.146 34.256 140.358 33.932 141.438 33.284C142.518 32.636 143.37 31.724 143.994 30.548C144.642 29.372 144.966 27.992 144.966 26.408V24.752C144.246 26.12 143.226 27.2 141.906 27.992C140.61 28.784 139.11 29.18 137.406 29.18ZM137.694 26.66C139.11 26.66 140.358 26.348 141.438 25.724C142.542 25.076 143.406 24.188 144.03 23.06C144.654 21.932 144.966 20.636 144.966 19.172C144.966 17.708 144.654 16.412 144.03 15.284C143.406 14.132 142.542 13.244 141.438 12.62C140.358 11.972 139.11 11.648 137.694 11.648C136.278 11.648 135.018 11.972 133.914 12.62C132.81 13.244 131.946 14.132 131.322 15.284C130.698 16.412 130.386 17.708 130.386 19.172C130.386 20.636 130.698 21.932 131.322 23.06C131.946 24.188 132.81 25.076 133.914 25.724C135.018 26.348 136.278 26.66 137.694 26.66Z"
          fill="black"
        />
        <path
          id="P12"
          d="M153.201 29.576C152.625 29.576 152.133 29.372 151.725 28.964C151.317 28.556 151.113 28.064 151.113 27.488C151.113 26.888 151.317 26.384 151.725 25.976C152.157 25.568 152.649 25.364 153.201 25.364C153.777 25.364 154.269 25.58 154.677 26.012C155.085 26.42 155.289 26.912 155.289 27.488C155.289 28.04 155.085 28.532 154.677 28.964C154.293 29.372 153.801 29.576 153.201 29.576Z"
          fill="black"
        />
        <path
          id="P22"
          d="M160.431 29.576C159.855 29.576 159.363 29.372 158.955 28.964C158.547 28.556 158.343 28.064 158.343 27.488C158.343 26.888 158.547 26.384 158.955 25.976C159.387 25.568 159.879 25.364 160.431 25.364C161.007 25.364 161.499 25.58 161.907 26.012C162.315 26.42 162.519 26.912 162.519 27.488C162.519 28.04 162.315 28.532 161.907 28.964C161.523 29.372 161.031 29.576 160.431 29.576Z"
          fill="black"
        />
        <path
          id="P32"
          d="M167.66 29.576C167.084 29.576 166.592 29.372 166.184 28.964C165.776 28.556 165.572 28.064 165.572 27.488C165.572 26.888 165.776 26.384 166.184 25.976C166.616 25.568 167.108 25.364 167.66 25.364C168.236 25.364 168.728 25.58 169.136 26.012C169.544 26.42 169.748 26.912 169.748 27.488C169.748 28.04 169.544 28.532 169.136 28.964C168.752 29.372 168.26 29.576 167.66 29.576Z"
          fill="black"
        />
      </g>
    </svg>
  )
}

export default Loading
